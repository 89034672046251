import React, { useEffect, useRef, useState } from "react";
import Icons from "../../assets/icons";
import Table from "./Table";
import { Button } from "../atoms/buttons";
import SearchOptions from "./SeacrhOptions";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "./TableSkeleton";
import { POST, GET } from "../../services/apiServices"; // Add GET here
import toast from "react-hot-toast";
import ExportPopup from "./ExportPopup"; // Add this import
import { postExport } from "../../redux/slices/pastExportsSlice";
import {
  fetchUserDetail,
  setCredit,
} from "../../redux/slices/getUserDetailSlice";

import Spinner from "../atoms/Spinner"; // Add this import
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { triggerScraper } from "../../redux/slices/searchSlice";

const MainContent = () => {
  const dispatch = useDispatch();

  const [showTable, setShowTable] = useState(true);
  const toggleTable = () => setShowTable(false);
  const [filters, setFilters] = useState(null); // To store filters from SearchOptions
  const searchOptionsRef = useRef(null); // Create the ref for SearchOptions

  const [accessLoading, setAccessLoading] = useState({
    isLoading: false,
    id: "",
  });
  const [finalData, setFinalData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [showExportPopup, setShowExportPopup] = useState(false); // Add this state
  const [isLoading, setIsLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [unpurchasedContacts, setUnpurchasedContacts] = useState(undefined);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatData, setPaginatData] = useState({
    currentPage: 1,
    totalPages: null,
    totalItems: null,
    itemsPerPage: 10,
  });
  const {
    filters: searchFilterData,
    loading,
    error,
    isScraperRunning,
    scraperError,
  } = useSelector((state) => state.search);
  const { userDetail, loading: userDetailLoading } = useSelector(
    (state) => state.userDetail
  );

  const isEmailConfirmed = userDetail?.data?.customer?.is_email_confirmed;

  const searchData = searchFilterData?.data;
  const navigate = useNavigate();
  const isCompanyPresent = userDetail?.data?.customer;
  if (isCompanyPresent && isCompanyPresent?.company === null) {
    navigate("/onboard");
  }

  const contacts = searchFilterData?.count;
  const companyCount = searchFilterData?.companyCount;

  const handleSaveFilters = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  const handleSelectedId = (ids) => {
    setSelectedIds(ids);
    setSelectedCount(ids.length);
  };
  const handleSetCount = () => {
    setSelectedIds([]);
    setSelectedCount(0);
    setCurrentPage(1);
  };
  const getContactAccess = async (id) => {
    try {
      const response = await GET(`search/contact-info/${id}`); // Replace with the actual API endpoint for industries
      console.log("response", response);
      var credit = document.querySelector("#custom-credit");
      credit.innerText = response?.credits;
      localStorage.setItem("credits", response?.credits);
      // dispatch(setCredit(response?.credits));
      return response.data; // Return the industry data
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcess = async (id, type) => {
    toast.dismiss(); // Dismiss any previous toasts
    // Check if email confirmation is required
    if (!isEmailConfirmed) {
      toast.error("Email is not confirmed. Access denied.");
      return;
    }

    const selectedContact = modifiedData.find((item) => item.id === id);

    // Check if the email or phone is already shown (not masked)
    if (
      selectedContact?.email?.includes("*") ||
      selectedContact?.phone?.includes("*")
    ) {
      setAccessLoading({ isLoading: true, id });

      const data = await getContactAccess(id);

      if (data?.telephone_number && data?.email) {
        const updatedSearchFilterData = modifiedData.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              phone: data.telephone_number,
              email: data.email,
              disabled: true, // Disable the fields after fetching the data
            };
          }
          return item;
        });
        setModifiedData(updatedSearchFilterData);
      } else {
        toast.error("Contact details not available for this lead.");
      }
      setAccessLoading({ isLoading: false, id: "" });
    } else {
      // If data is already shown, skip API call
      var inp = document.createElement("input");
      document.body.appendChild(inp);
      if (type === "email") {
        inp.value = selectedContact.email;
        inp.select();
        document.execCommand("copy");
        document.body.removeChild(inp);
        // navigator.clipboard.writeText(`Email: ${selectedContact.email}`);
      } else {
        inp.value = selectedContact.phone;
        inp.select();
        document.execCommand("copy");
        document.body.removeChild(inp);
        // navigator.clipboard.writeText(`Phone: ${selectedContact.phone}`);
      }
      toast.success(
        `${type === "email" ? "Email" : "Phone"} copied to clipboard`
      );
    }
  };

  const handleRunExampleSearch = async () => {
    toggleTable(); // Toggle the table view when running the search
    if (searchOptionsRef.current) {
      searchOptionsRef.current.handleRunExampleSearch();
    }
  };
  const handlePageCahnge = async (page) => {
    setCurrentPage(page);
    if (searchOptionsRef.current) {
      searchOptionsRef.current.handlepageChange(page);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // This makes the scroll smooth; you can omit it if not needed
      });
    }
  };

  const handleExportClick = async () => {
    setIsExportLoading(true);
    try {
      const token = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const intersectionResponse = await POST(
        `export/leads-intersection`,
        filters,
        config
      );
      console.log("Leads Intersection Response:", intersectionResponse);

      // Set the unpurchasedContacts value from the response
      setUnpurchasedContacts(intersectionResponse.data.unpurchasedContacts);

      // After successful API call, show the export popup
      setShowExportPopup(true);
    } catch (error) {
      console.error("Error calling leads-intersection API:", error);
      toast.error("Failed to prepare export. Please try again.");
    } finally {
      setIsExportLoading(false);
    }
  };

  const handleCloseExportPopup = () => {
    setShowExportPopup(false);
  };

  const handleExport = (exportData) => {
    setIsLoading(true);
    // Dispatch the postExport action
    exportData = {
      ...exportData,
      lead_ids: selectedIds && selectedIds.length > 0 ? selectedIds : [], // Send selectedIds if present, otherwise an empty array
    };

    dispatch(postExport(exportData))
      .unwrap()
      .then((response) => {
        dispatch(fetchUserDetail());
        // Handle the successful export logic here
        setShowExportPopup(false);
        toast.success(
          "Your export has been confirmed. As soon as it's verified, we'll email you."
        );
        navigate("/past-exports");
      })
      .catch((error) => {
        // Handle any errors that occur during export
        console.error("Export failed:", error);

        // Show error message in toast
        if (error.message) {
          toast.error(`${error.message}`);
        } else {
          toast.error("Export failed. Please try again later.");
        }
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false once export is done
      });
  };

  const headers = [
    "Name",
    "Title",
    "Company",
    "Location",
    "Email",
    "Phone",
    "Industry",
  ];

  const tableRow = (data) => {
    return data?.map((search) => [
      <div className="flex items-center gap-3">
        <div className="text-[#313d31] text-xs font-normal text-ellipsis overflow-hidden">
          {search?.name}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.TravelWhite />
        <div className="text-[#313d31] text-xs font-normal">
          {search?.title}
        </div>
      </div>,
      <a
        href={
          search?.companyLink?.startsWith("https")
            ? search?.companyLink
            : `https://${search?.companyLink}`
        }
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex gap-1 items-center">
          <Icons.CompanyIcon />
          <div className="text-[#313d31] text-xs font-normal">
            {search?.company}
          </div>
        </div>
      </a>,
      <div className="flex gap-1 items-center">
        <div className="w-4">
          <Icons.LocationWhite />
        </div>
        <div className="text-[#313d31] text-xs font-normal">
          {search?.location}
        </div>
      </div>,
      <div
        onClick={() =>
          !accessLoading.isLoading && handleAcess(search?.id, "email")
        }
        className="p-2 whitespace-break-spaces rounded-sm border border-[#dae7de] justify-start items-center gap-1 inline-flex cursor-pointer max-w-[13ch] overflow-hidden"
      >
        <div>
          <Icons.EmailWhite />
        </div>
        <div className="text-[#313d31] text-xs font-normal font-['DM Sans'] select-none leading-none ellipses">
          {accessLoading.isLoading && accessLoading.id === search?.id
            ? "Loading..."
            : search?.disabled
            ? search?.email // Show email if disabled
            : search?.email?.includes("*")
            ? "Access Info"
            : search?.email
            ? search?.email
            : "---"}
        </div>
      </div>,
      <div
        onClick={() =>
          !accessLoading.isLoading && handleAcess(search?.id, "phone")
        }
        className="p-2 whitespace-break-spaces rounded-sm border border-[#dae7de] justify-start items-center gap-1 inline-flex cursor-pointer max-w-[11ch] overflow-hidden"
      >
        <div>
          <Icons.PhoneWhite />
        </div>
        <div className="text-[#313d31] text-xs font-normal font-['DM Sans'] user leading-none ellipses">
          {accessLoading.isLoading && accessLoading.id === search?.id
            ? "Loading..."
            : search?.disabled
            ? search?.phone // Show phone if disabled
            : search?.phone?.includes("*")
            ? "Access Info"
            : search?.phone
            ? search?.phone
            : "---"}
        </div>
      </div>,
      <div className="flex items-center gap-3">
        <div className="text-[#313d31] text-xs font-normal text-ellipsis overflow-hidden">
          {search?.industry}
        </div>
      </div>,
    ]);
  };

  useEffect(() => {
    setModifiedData(searchFilterData?.data);
    console.log("searchFilterData", searchFilterData);
    const data = tableRow(searchFilterData?.data);
    setPaginatData(searchFilterData?.pagination);
    setFinalData(data);

    // Check if we need to trigger the scraper
    if (searchFilterData?.data?.length === 0 && filters) {
      const hasJobTitle = filters?.keywords?.title_full?.length > 0;
      const hasCompany = filters?.keywords?.company?.length > 0;

      if (hasJobTitle) {
        // Trigger scraper with current filters
        dispatch(
          triggerScraper({
            companies: filters?.keywords?.company
              ? filters?.keywords?.company
              : [],
            titles: filters?.keywords?.title_full,
          })
        ).then((response) => {
          // If scraper found new data, update the UI
          if (response.payload?.data?.length > 0) {
            setModifiedData(response.payload.data);
            const newData = tableRow(response.payload.data);
            setFinalData(newData);
            setPaginatData(response.payload.pagination);
          }
        });
      }
    }
  }, [searchFilterData]);

  useEffect(() => {
    const data = tableRow(modifiedData);
    setFinalData(data);
  }, [modifiedData, accessLoading]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <SearchOptions
        toggleTable={toggleTable}
        onSaveFilters={handleSaveFilters}
        ref={searchOptionsRef}
        setSelectedIds={handleSetCount}
      />
      <div className="flex-1 min-w-0">
        {loading ? (
          <div className="px-4">
            <TableSkeleton rows={10} columns={6} header={true} />
          </div>
        ) : showTable ? (
          <div className="text-center bg-[#F4F9F5] border border-[#DAE7DE] rounded-lg max-w-md flex flex-col items-center gap-3 mx-auto p-6 mt-8">
            <span className="icon">
              <img
                src={`${process.env.PUBLIC_URL}/images/search.png`}
                className="h-9 w-9"
                alt="search icon"
              />
            </span>
            <h2 className="text-base font-bold mt-0">
              Hello there, nice to see you!
            </h2>
            <button
              className="bg-lime-green text-white py-2 px-4 rounded-lg border-none cursor-pointer flex gap-3 items-center justify-center text-base font-font-medium my-2"
              onClick={handleRunExampleSearch}
            >
              <Icons.SearchIcon />
              Run an example search for me
            </button>
            <p className="text-[#313D31] text-sm">
              This example will search for "
              <span className="text-lime-green">Presidents</span> and{" "}
              <span className="text-lime-green">Owners</span> of{" "}
              <span className="text-lime-green">Prepackaged Software</span>".
            </p>
          </div>
        ) : searchFilterData?.data?.length === 0 ? (
          <div className="text-center bg-[#F4F9F5] border border-[#DAE7DE] rounded-lg max-w-md flex flex-col items-center gap-3 mx-auto p-6 mt-8">
            {isScraperRunning ? (
              <>
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-lime-green"></div>
                <h2 className="text-base font-bold mt-0">
                  Looking for more leads...
                </h2>
                <p className="text-[#313D31] text-sm">
                  We're actively searching for leads matching your criteria.
                  This may take a few minutes.
                </p>
              </>
            ) : scraperError ? (
              <>
                <div className="text-red-500">
                  <Icons.InfoIcon size="32" />
                </div>
                <h2 className="text-base font-bold mt-0 text-red-500">
                  Unable to find leads
                </h2>
                <p className="text-[#313D31] text-sm">
                  {scraperError.message ||
                    "An error occurred while searching for leads. Please try again."}
                </p>
              </>
            ) : (
              <>
                <Icons.SearchIcon />
                <h2 className="text-base font-bold mt-0">No leads found</h2>
                <p className="text-[#313D31] text-sm">
                  Try adjusting your search criteria or wait while we look for
                  more leads.
                </p>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-wrap md:justify-between justify-center md:text-left text-center gap-3 items-center mt-5 mb-5 w-full px-5">
              <div className="flex flex-col">
                <div>
                  <span className="text-[#1d201d] text-xl font-bold leading-7">
                    &nbsp; {contacts ? contacts : "0"}&nbsp;
                  </span>
                  <span className="text-[#313d31] text-xl font-normal leading-7">
                    contacts at
                  </span>
                  <span className="text-[#1d201d] text-xl font-bold leading-7">
                    &nbsp; {companyCount ? companyCount : "0"}&nbsp;
                  </span>
                  <span className="text-[#313d31] text-xl font-normal leading-7">
                    companies
                  </span>
                </div>
                <div className="text-[#313d31] text-sm mt-2 ml-1">
                  Selected: {selectedCount}{" "}
                  {selectedCount <= 1 ? "contact" : "contacts"}
                </div>
              </div>
              <Button
                className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded disabled:cursor-not-allowed"
                onClick={handleExportClick}
                disabled={isExportLoading || finalData?.length === 0}
              >
                {isExportLoading ? <Spinner /> : "Export"}
              </Button>
            </div>
            <div className="px-6">
              <Table
                headers={headers}
                data={finalData}
                showChecked={true}
                searchData={searchData}
                handleSelectedId={handleSelectedId}
                hidePagination={true}
                selectedIds={selectedIds}
              />
            </div>
            {paginatData?.totalPages > 1 ? (
              <div className="mb-10">
                <Pagination
                  currentPage={currentPage}
                  totalPages={paginatData?.totalPages}
                  onPageChange={handlePageCahnge}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {showExportPopup && (
        <ExportPopup
          onClose={handleCloseExportPopup}
          onExport={handleExport}
          searchParams={filters}
          isLoading={isLoading}
          unpurchasedContacts={unpurchasedContacts}
          selectedIds={selectedIds}
        />
      )}
    </>
  );
};

export default MainContent;
